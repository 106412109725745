<template>
  <!-- 意向客户-详情 -->
  <div>
    <Header back="返回" title="意向客户" index="首页" titleOne="合同管理" titleTwo="意向客户" beforeTitle="详情" />
    <div class="content">
      <h1>客户信息</h1>
      <ul class="flex_ul">
        <div class="flex_box">
          <li>
            <p>企业名称</p>
            <span>{{detailForm.company_name}}</span>
          </li>
          <li>
            <p>企业类型</p>
            <span v-if="detailForm.com_type == 1">有限责任公司</span>
            <span v-if="detailForm.com_type == 2">股份有限公司</span>
            <span v-if="detailForm.com_type == 3">国企</span>
            <span v-if="detailForm.com_type == 4">外商投资企业</span>
            <span v-if="detailForm.com_type == 5">独资企业</span>
            <span v-if="detailForm.com_type == 6">个体工商户</span>
            <span v-if="detailForm.com_type == 7">联营企业</span>
            <span v-if="detailForm.com_type == 8">集体所有制</span>
            <span v-if="detailForm.com_type == 9">有限合</span>
            <span v-if="detailForm.com_type == 10">普通合伙</span>
          </li>
          <li>
            <p>注册时间</p>
            <span>{{detailForm.register_time}}</span>
          </li>
          <li>
            <p>注册资本（万元）</p>
            <span>{{detailForm.register_capital}}</span>
          </li>
          <li>
            <p>所属区域</p>
            <span>{{detailForm.area}}</span>
          </li>
          <li>
            <p>所属行业</p>
            <span>{{detailForm.industry_two_name}}</span>
          </li>
          <li>
            <p>注册地址</p>
            <span>{{detailForm.area}}</span>
          </li>
          <li>
            <p>公司地址</p>
            <span>{{detailForm.address}}</span>
          </li>
        </div>
        <div class="flex_box">
          <li>
            <p>法人代表</p>
            <span>{{detailForm.legal_person}}</span>
          </li>
          <li>
            <p>客户联系人</p>
            <span>{{detailForm.custom_contact}}</span>
          </li>
          <li>
            <p>客户联系手机</p>
            <span>{{detailForm.custom_tel}}</span>
          </li>
          <li>
            <p>客户级别</p>
            <span>{{detailForm.level}}</span>
          </li>
          <li>
            <p>专利数量</p>
            <span>{{detailForm.patent_count}}</span>
          </li>
          <li>
            <p>软著数量</p>
            <span>{{detailForm.soft_count}}</span>
          </li>
          <li>
            <p>商标数量</p>
            <span>{{detailForm.brand_count}}</span>
          </li>
        </div>
      </ul>
      <div>
        <ul>
          <li>
            <p>经营范围</p>
            <span>{{detailForm.jyfw}}</span>
          </li>
          <li>
            <p>跟进情况</p>
            <span>{{detailForm.follow_up}}</span>
          </li>
          <li>
            <p>拜访情况</p>
            <span>{{detailForm.visit}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      detailForm: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.axios.get('/api/company/view', { params: { token: localStorage.getItem('token'), id: this.$route.query.id } }).then((res) => {
        this.detailForm = res.data
      })
    },
  },
}
</script>

<style scoped>
.flex_ul {
  width: 100%;
  display: flex;
}
.flex_ul .flex_box {
  width: 50%;
}
.flex_ul .flex_box li {
  height: 26px;
}
.content ul li {
  display: flex;
  align-items: center;
}
.flex_ul li {
  height: 40px !important;
}
.content ul li p {
  display: inline-block;
  width: 20%;
}
.content ul li span {
  color: #909399;
}
</style>